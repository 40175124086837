import TypeIt from "typeit-react";

import React from 'react';
import { WebTelemetryAnchor } from "./WebTelAnchor";
import { ProjectBaseTemplate } from "./ProjectBaseTemplate";
import { RoughNotation } from "react-rough-notation";

const EventAnalysisDescription:React.FC<{}> = () => {
    return (
        <ol className='text-base m-4 list-decimal'>
            <li className='m-4'>
                Open Sourced the first library that contains both &nbsp;
                <a className='text-blue-600 visited:text-purple-600' href='https://arxiv.org/abs/1508.03534'>
                Event Coincidence Analysis (ECA) 
                </a>
                &nbsp;and&nbsp; 
                <a className='text-blue-600 visited:text-purple-600' href='https://pubs.aip.org/aip/cha/article-abstract/30/3/033102/1030599/Event-synchrony-measures-for-functional-climate?redirectedFrom=fulltext'>
                Event Synchronisation (ES): 
                </a>
                algorithms that are used to find correlation 
                b/w event series with applications like detecting extreme climate events.
            </li>
            <li className='m-4'>
                The only other FOSS library that contains ECA is &nbsp;
                <a className='text-blue-600 visited:text-purple-600' href='https://www.sciencedirect.com/science/article/abs/pii/S0098300416305489'>
                    CoinCalc
                </a>
                &nbsp;
                 in R. This library is 
                 
                 <RoughNotation type='underline' show={true} > 
              &nbsp; 180 times faster &nbsp; 
            </RoughNotation> 
                  than 
                CoinCalc on CPU and 
                <RoughNotation type='underline' show={true} > 
              &nbsp; 7200 times faster  &nbsp; 
            </RoughNotation> 
                on GPU. 
            </li>
            <li className='m-4'>
                The library was downloaded &nbsp;
                <a className='text-blue-600 visited:text-purple-600' href='https://pypistats.org/packages/event-analysis'> 
                    over 800 times
                </a>
                &nbsp; in the first 48 hours of its release.
            </li>
            </ol>
    )
}

const EventAnalysisButton:React.FC<{}> = () => {
    return (
    <>
        <WebTelemetryAnchor
            className="m-2 p-2 rounded-lg border border-black w-24 text-center"
            href="https://github.com/professorcode1/Event-Analysis"
        >
            Github
        </WebTelemetryAnchor>
        <WebTelemetryAnchor 
            className="m-2 p-2 rounded-lg border border-black w-24 text-center"
            href="https://pypi.org/project/event-analysis/"
        >
            PyPi
        </WebTelemetryAnchor>
        </>
    )
}

const EventAnalysis:React.FC<{}> = () => {
    return (
        <ProjectBaseTemplate 
            name="Event Analysis"
            description={<EventAnalysisDescription />}
            buttons={<EventAnalysisButton />}
            asset={null}
        />
    );
}

export {EventAnalysis}